import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import Img from "gatsby-image"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import { BallTriangle as Loading } from "../../components/Common/loaders"
import { Eye, EyeSlash } from "../../components/Common/fontawesome"
import Button from "../../components/Button"
import { Form, EyeHolder } from "../../components/Form/StyledParts"
import { Danger as Err, Success } from "../../components/Form/Alerts"

const ForgotBtn = Styled.h5`
    display: inline-block;
    cursor: pointer;
    margin-top: 8px;
    &:hover {
        color: ${({ theme }) => theme.primary}
    }
`
const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: ${({ height }) => (height ? height : "auto")};

  & > img {
    object-fit: ${({ fit }) =>
      fit ? `${fit} !important` : "cover !important"};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
    font-family: ${({ fit }) =>
      fit ? `object-fit: ${fit} !important` : `object-fit: cover !important`};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
  }
`

const Login = ({
  data,
  authenticated,
  authError,
  loading,
  Login,
  ForgotPassword,
  forgotpassword,
}) => {
  const [isMasked, setIsMasked] = useState(true)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [loginError, setLoginError] = useState("")

  useEffect(() => {
    if (authenticated) {
      navigate("/inbetween")
    }
  }, [])
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async data => {
    try {
      const { email, password } = data
      Login(email, password)
    } catch (error) {
      console.log(`error: ${error}`)
      setLoginError("Error on login")
    }
  }
  const onSubmitForgot = async data => {
    try {
      const { emailforgot } = data
      ForgotPassword(emailforgot)
    } catch (errorForgot) {
      console.log(`errorForgot: ${errorForgot}`)
      setLoginError("Error recovering password")
    }
  }
  const renderError = () => {
    if (loginError) {
      return <Err>{loginError}</Err>
    } else if (authError) {
      return <Err>{authError}</Err>
    } else if (forgotpassword) {
      return <Success>{forgotpassword}</Success>
    }
  }
  const renderForm = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <Loading color={`#ff0000`} size={`75`} />
          </div>
        </div>
      )
    } else if (forgotPassword) {
      return (
        <Form onSubmit={handleSubmit(onSubmitForgot)}>
          <div className="row">
            <div className="col-12 text-center py-1">
              <h3>Enter email to recover your password</h3>
            </div>
            <div className="col-12">
              <input
                type="email"
                name="emailforgot"
                placeholder="Email"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>
            <div className="col-12">
              {errors.emailforgot && <Err>{errors.emailforgot.message}</Err>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Button onClick={handleSubmit(onSubmitForgot)}>
                Recover Password
              </Button>
            </div>
            <div className="col-12 text-center">{renderError()}</div>
          </div>
        </Form>
      )
    }
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <input
              type="email"
              name="email"
              placeholder="Email"
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
              })}
            />
          </div>
          <div className="col-12">
            {errors.email && <Err>{errors.email.message}</Err>}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <input
              type={isMasked ? "password" : "text"}
              placeholder="Password"
              name="password"
              ref={register({
                required: "Password is required",
                maxLength: 100,
              })}
            />
            {isMasked ? (
              <EyeHolder>
                <Eye width={24} onClick={() => setIsMasked(!isMasked)} />
              </EyeHolder>
            ) : (
              <EyeHolder>
                <EyeSlash width={24} onClick={() => setIsMasked(!isMasked)} />
              </EyeHolder>
            )}
          </div>
          <div className="col-12">
            {errors.password && <Err>{errors.password.message}</Err>}
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Button onClick={handleSubmit(onSubmit)}>Login</Button>
          </div>
          <div className="col-12 text-center">
            <ForgotBtn onClick={() => setForgotPassword(!forgotPassword)}>
              Forgot Password?
            </ForgotBtn>
          </div>
          <div className="col-12 text-center">{renderError()}</div>
        </div>
      </Form>
    )
  }
  const promoTall = data.promoTall.edges[0].node.childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Dixie Bracket | Login" />
      <div className="row">
        <div className="col-12 col-md-6 mr-auto ml-auto">
          <BgImage
            fluid={promoTall}
            alt="Brandon Hunt - State Farm"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </div>
      <div className="row py-4">
        <div className="col-12 text-center">
          <h2>Login</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mr-auto ml-auto">
          <div className="container-fluid">{renderForm()}</div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query loginQuery {
    promoTall: allFile(filter: { name: { eq: "promo_550x360" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 550) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    loading: state.auth.loading,
    authenticated: state.auth.authenticated,
    authError: state.auth.authError,
    forgotpassword: state.auth.forgotpassword,
  }
}

export default connect(MapStateToProps, actions)(Login)
